import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Paper,
  PaperHeader,
  Select,
  Spinner,
  Title,
  TitleSecondary,
} from '../../components';

import styles from './campaignNotifications.module.scss';
import { config } from '../../api/config';
import { Checkbox, Input } from '../../components';
import { LIMITS } from './campaignNotifications.validation';
import 'react-datepicker/dist/react-datepicker.css';
import LabelElement from '../../components/form/_elements/labelElement/labelElement';
import { CAMPAIGN_TYPE_SMS } from './constants';
import CampaignNotificationSegments from './campaignNotifications.segments';

const Form = ({
  values,
  onChange,
  onCheckboxChange,
  onSubmit,
  loading,
  setConditionalAudience,
  campaignNotificationsTypes,
  fetchPotentialAudience,
  potentialAudienceResult,
  potentialAudienceLoading,
  disableEdit,
  conditions,
  setConditions,
  displayPotentialAudience,
  setDisplayPotentialAudience,
  setSubmitButton,
}) => {
  return (
    <form onSubmit={onSubmit} method="POST">
      <Paper size="sm">
        <PaperHeader>
          <Title total="">Pridėti kampaniją</Title>
        </PaperHeader>
        <div className={styles.formdata}>
          <div className={styles.contentSection}>
            <TitleSecondary>Tipas</TitleSecondary>
            <div className={styles.notificiationTypeSelect}>
              <div className={styles.labelContainer}>
                <LabelElement label="Pasirinkite tipą" htmlFor="type" />
              </div>
              <Select
                name="type"
                options={campaignNotificationsTypes}
                selectedValue={values.type}
                onChange={onChange}
                disabled={disableEdit}
              />
            </div>
          </div>
          <TitleSecondary>Bendra informacija</TitleSecondary>
          {/* title */}
          <Input
            name="title"
            label="Kampanijos pavadinimas"
            type="text"
            disabled={loading || disableEdit}
            maxLength={LIMITS.title}
            onChange={onChange}
            value={values.title}
            required
          />

          {/* notification titles */}
          <Input
            name="notification_title_lt"
            label="Pranešimo pavadinimas (LT)"
            icon="flag-lt"
            type="text"
            disabled={loading || disableEdit}
            maxLength={LIMITS.notification_title}
            onChange={onChange}
            value={values.notification_title_lt}
            required
          />
          <Input
            name="notification_title_en"
            label="Pranešimo pavadinimas (EN)"
            icon="flag-en"
            type="text"
            disabled={loading || disableEdit}
            maxLength={LIMITS.notification_title}
            onChange={onChange}
            value={values.notification_title_en}
            required
          />
          <Input
            name="notification_title_ru"
            label="Pranešimo pavadinimas (RU)"
            icon="flag-ru"
            type="text"
            disabled={loading || disableEdit}
            maxLength={LIMITS.notification_title}
            onChange={onChange}
            value={values.notification_title_ru}
            required
          />

          {/* notification body */}
          <Input
            name="notification_body_lt"
            label="Pranešimo tekstas (LT)"
            icon="flag-lt"
            type="text"
            disabled={loading || disableEdit}
            maxLength={LIMITS.notification_body}
            onChange={onChange}
            value={values.notification_body_lt}
            required
          />
          <Input
            name="notification_body_en"
            label="Pranešimo tekstas (EN)"
            icon="flag-en"
            type="text"
            disabled={loading || disableEdit}
            maxLength={LIMITS.notification_body}
            onChange={onChange}
            value={values.notification_body_en}
            required
          />
          <Input
            name="notification_body_ru"
            label="Pranešimo tekstas (RU)"
            icon="flag-ru"
            type="text"
            disabled={loading || disableEdit}
            maxLength={LIMITS.notification_body}
            onChange={onChange}
            value={values.notification_body_ru}
            required
          />
          {parseInt(values?.type) !== CAMPAIGN_TYPE_SMS && (
            <Input
              name="deep_link"
              label="Deeplink"
              type="text"
              disabled={loading || disableEdit}
              onChange={onChange}
              value={values.deep_link}
            />
          )}

          <h5 className={styles.selectionLabel}>
            Pranešimo siuntimo data ir laikas
          </h5>
          <div className={styles.inputsRow}>
            <Input
              name="send_at_date"
              type="date"
              max="2999-12-31"
              disabled={loading || disableEdit}
              onChange={onChange}
              value={values.send_at_date}
              required
            />
            <Input
              name="send_at_time"
              type="time"
              min="0:00"
              max="24:00"
              disabled={loading || disableEdit}
              onChange={onChange}
              value={values.send_at_time}
              required
            />
          </div>

          <div className={styles.inputsColumn}>
            <Checkbox
              label="Segmentavimas"
              name="conditionalAudience"
              checked={values.conditionalAudience}
              value={values.conditionalAudience}
              onClick={setConditionalAudience}
              disabled={loading || disableEdit}
            />
            <Checkbox
              label="NPS"
              name="nps"
              checked={values.nps}
              value={values.nps}
              onChange={onCheckboxChange}
              disabled={loading || disableEdit}
            />
          </div>
        </div>
      </Paper>

      {values.conditionalAudience && (
        <Paper size="sm">
          <PaperHeader>
            <Title total="">Segmentavimas</Title>
          </PaperHeader>
          <div className={styles.segments}>
            <h5 className={styles.segmentsInputLabel}>Data nuo</h5>
            <div className={styles.inputsRow}>
              <Input
                name="condition_from_date"
                type="date"
                max="2999-12-31"
                disabled={loading || disableEdit}
                onChange={onChange}
                value={values.condition_from_date}
                required
              />
              <Input
                name="condition_from_time"
                type="time"
                min="0:00"
                max="24:00"
                disabled={loading || disableEdit}
                onChange={onChange}
                value={values.condition_from_time}
                required
              />
            </div>
            <h5 className={styles.segmentsInputLabel}>Data iki</h5>
            <div className={styles.inputsRow}>
              <Input
                name="condition_to_date"
                type="date"
                max="2999-12-31"
                disabled={loading || disableEdit}
                onChange={onChange}
                value={values.condition_to_date}
                required
              />
              <Input
                name="condition_to_time"
                type="time"
                min="0:00"
                max="24:00"
                disabled={loading || disableEdit}
                onChange={onChange}
                value={values.condition_to_time}
                required
              />
            </div>
            <CampaignNotificationSegments
              conditions={conditions}
              setConditions={setConditions}
              disableEdit={disableEdit}
            />
          </div>
        </Paper>
      )}
      <Paper size="sm">
        <div className={styles.potentialAudienceSection}>
          <Button
            color="primary"
            disabled={potentialAudienceLoading}
            onClick={() => {
              fetchPotentialAudience();
              setDisplayPotentialAudience(true);
            }}
          >
            Skaičiuoti potencialią auditoriją
          </Button>
          {displayPotentialAudience && potentialAudienceResult >= 0 && (
            <p>{potentialAudienceResult} klientai</p>
          )}
        </div>
        <div className={styles.actions}>
          {loading && <Spinner />}
          <Button color="dim" url={config.APP_PATHS.campaignNotifications}>
            Atšaukti
          </Button>
          <Button
            color="primary"
            type="submit"
            onClick={() => {
              setSubmitButton('draft');
            }}
            disabled={disableEdit || loading}
          >
            Išsaugoti juodraštį
          </Button>
          <Button
            color="primary"
            type="sumbit"
            onClick={() => {
              setSubmitButton('send');
            }}
            disabled={disableEdit || loading}
          >
            Aktyvuoti siuntimą
          </Button>
        </div>
      </Paper>
    </form>
  );
};

const mapStateToProps = state => ({});

Form.propTypes = {
  values: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Form);
