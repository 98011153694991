import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { config } from '../../api/config';
import { actions } from '../../state';
import { Header, Breadcrumb } from '../../components';
import Form from './campaignNotifications.form';
import {
  CAMPAIGN_STATUS_ACTIVE,
  CAMPAIGN_STATUS_DRAFT,
  CAMPAIGN_TYPE_PUSH,
  CAMPAIGN_TYPE_PUSH_NAME,
  CAMPAIGN_TYPE_SMS,
  CAMPAIGN_TYPE_SMS_NAME,
  CAMPAIGN_TYPE_SMS_PUSH,
  CAMPAIGN_TYPE_SMS_PUSH_NAME,
} from './constants';
import { validationErrors } from './campaignNotifications.validation';
import { CampaignNotificationsContext } from './campaignNotifications.context';
import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns';
import { formatLocaleDate } from '../../utils';

const AddCampaignNotification = ({
  dispatch,
  history,
  campaigns: { loading, potentialAudienceResult, potentialAudienceLoading },
}) => {
  const lastMonth = subMonths(Date.now(), 1);

  const [values, setValues] = useState({
    type: CAMPAIGN_TYPE_SMS,
    title: '',
    notification_title_lt: '',
    notification_title_en: '',
    notification_title_ru: '',
    notification_body_lt: '',
    notification_body_en: '',
    notification_body_ru: '',
    deep_link: '',
    send_at_date: format(Date.now(), 'yyyy-MM-dd'),
    send_at_time: '',
    conditionalAudience: false,
    condition_from_date: format(startOfMonth(lastMonth), 'yyyy-MM-dd'),
    condition_from_time: '00:00',
    condition_to_date: format(endOfMonth(lastMonth), 'yyyy-MM-dd'),
    condition_to_time: '23:59',
    nps: false,
  });

  const [conditions, setConditions] = useState([]);
  const [displayPotentialAudience, setDisplayPotentialAudience] = useState(
    false
  );
  const [submitButton, setSubmitButton] = useState(null);

  const { prepareConditionsForPayload } = useContext(
    CampaignNotificationsContext
  );

  const campaignNotificationsTypes = [
    {
      name: CAMPAIGN_TYPE_SMS_NAME,
      value: CAMPAIGN_TYPE_SMS,
    },
    {
      name: CAMPAIGN_TYPE_PUSH_NAME,
      value: CAMPAIGN_TYPE_PUSH,
    },
    {
      name: CAMPAIGN_TYPE_SMS_PUSH_NAME,
      value: CAMPAIGN_TYPE_SMS_PUSH,
    },
  ];

  const handleInputChange = ({ target: { name, value } }) => {
    setValues({ ...values, [name]: value });
  };

  const handleCheckboxChange = ({ currentTarget: { id, value } }) => {
    const newValue = value === 'true' ? false : true;
    setValues({
      ...values,
      [id]: newValue,
    });
  };

  const fetchPotentialAudience = () => {
    const conditionFrom =
      values.condition_from_date && values.condition_from_time
        ? new Date(
            values.condition_from_date + ' ' + values.condition_from_time
          ).toISOString()
        : undefined;

    const conditionTo =
      values.condition_to_date && values.condition_to_time
        ? new Date(
            values.condition_to_date + ' ' + values.condition_to_time
          ).toISOString()
        : undefined;

    const preparedConditions = conditions?.length
      ? prepareConditionsForPayload(conditions)
      : [];

    const body = {
      type: parseInt(values.type),
      nps: values.nps,
      ...(values?.conditionalAudience && {
        condition_from_date: conditionFrom,
        condition_to_date: conditionTo,
        conditions: preparedConditions,
      }),
    };
    dispatch(actions.campaigns.getCampaignPotentialAudience(body));
  };

  const setConditionalAudience = e => {
    const newValue = e.currentTarget.value === 'true' ? false : true;
    setValues({
      ...values,
      conditionalAudience: newValue,
    });
  };

  const getCampaignTypeName = type => {
    switch (parseInt(type)) {
      case CAMPAIGN_TYPE_SMS:
        return CAMPAIGN_TYPE_SMS_NAME;
      case CAMPAIGN_TYPE_PUSH:
        return CAMPAIGN_TYPE_PUSH_NAME;
      case CAMPAIGN_TYPE_SMS_PUSH:
        return CAMPAIGN_TYPE_SMS_PUSH_NAME;
      default:
        return '';
    }
  };

  const openCampaignConfirmModal = (body, history) => {
    const sendAtDate = new Date(
      values.send_at_date + ' ' + values.send_at_time
    );

    const modalPayload = {
      modalName: 'confirmCampaignModal',
      title: '',
      type: getCampaignTypeName(values.type),
      date: formatLocaleDate(sendAtDate),
      onConfirm: () => {
        dispatch(actions.campaigns.addCampaignAction(body, history));
      },
      onFetchPotentialAudience: () => {
        fetchPotentialAudience();
      },
    };
    dispatch(actions.modals.setModalVisible(modalPayload));
  };

  const handleSubmit = e => {
    e.preventDefault();

    const error = validationErrors(values);

    if (error) {
      dispatch(actions.error.setErrorAction({ msg: error }));
    } else {
      const sendAtDateToSubmit = new Date(
        values.send_at_date + ' ' + values.send_at_time
      ).toISOString();

      const conditionFromToSubmit =
        values.conditionalAudience &&
        values.condition_from_date &&
        values.condition_from_time
          ? new Date(
              values.condition_from_date + ' ' + values.condition_from_time
            ).toISOString()
          : null;

      const conditionToToSubmit =
        values.conditionalAudience &&
        values.condition_to_date &&
        values.condition_to_time
          ? new Date(
              values.condition_to_date + ' ' + values.condition_to_time
            ).toISOString()
          : null;

      const preparedConditions =
        values.conditionalAudience && conditions?.length
          ? prepareConditionsForPayload(conditions)
          : [];

      const isNotificationTypeSMS =
        parseInt(values?.type) === CAMPAIGN_TYPE_SMS;

      const campaignStatus =
        submitButton === 'send'
          ? CAMPAIGN_STATUS_ACTIVE
          : CAMPAIGN_STATUS_DRAFT;

      const body = {
        type: parseInt(values.type),
        status: campaignStatus,
        title: values.title,
        notification_title_lt: values.notification_title_lt,
        notification_title_en: values.notification_title_en,
        notification_title_ru: values.notification_title_ru,
        notification_body_lt: values.notification_body_lt,
        notification_body_en: values.notification_body_en,
        notification_body_ru: values.notification_body_ru,
        send_at: sendAtDateToSubmit,
        nps: values.nps,
        ...(!isNotificationTypeSMS &&
          values?.deep_link && {
            deep_link: values.deep_link,
          }),
        ...(values?.conditionalAudience && {
          condition_from_date: conditionFromToSubmit,
          condition_to_date: conditionToToSubmit,
          conditions: preparedConditions,
        }),
      };

      if (submitButton === 'send') {
        openCampaignConfirmModal(body, history);
      } else {
        dispatch(actions.campaigns.addCampaignAction(body, history));
      }
    }
  };

  return (
    <section>
      <Header size="sm">
        <Breadcrumb
          title={config.PAGES.campaignNotifications}
          baseUrl={config.APP_PATHS.campaignNotifications}
        />
      </Header>
      <Form
        values={values}
        onChange={handleInputChange}
        onCheckboxChange={handleCheckboxChange}
        onSubmit={handleSubmit}
        loading={loading}
        setConditionalAudience={setConditionalAudience}
        campaignNotificationsTypes={campaignNotificationsTypes}
        fetchPotentialAudience={fetchPotentialAudience}
        potentialAudienceResult={potentialAudienceResult}
        potentialAudienceLoading={potentialAudienceLoading}
        conditions={conditions}
        setConditions={setConditions}
        displayPotentialAudience={displayPotentialAudience}
        setDisplayPotentialAudience={setDisplayPotentialAudience}
        setSubmitButton={setSubmitButton}
      />
    </section>
  );
};

const mapStateToProps = state => ({
  campaigns: state.campaigns,
});

AddCampaignNotification.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  campaigns: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    potentialAudienceResult: PropTypes.number,
    potentialAudienceLoading: PropTypes.bool,
  }).isRequired,
};

export default connect(mapStateToProps)(AddCampaignNotification);
